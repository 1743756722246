import React from 'react';
import './App.css';
import Home from './Home';
import NavbarNew from './NavbarNew';
import Aanpak from './Aanpak';
import OverMij from './OverMij';
import Prijslijst from './Prijslijst';
// import {BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from './Footer';
import CookieConsent from 'react-cookie-consent';  
function App() {
  return (
    <>
      <div className="App"> 
        <NavbarNew />
        <Home />
        <OverMij />
        <Aanpak />
        {/*<Prijslijst />
         <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route  path="aanpak" element={<Aanpak />} />
          <Route  path="overMij" element={<OverMij />} />
          <Route  path="prijslijst" element={<Prijslijst />} />
        </Routes>
        </BrowserRouter> */}
        <CookieConsent
          location="bottom"
          buttonText="Akkoord"
          cookieName="No Show policy"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "25px" }}
          expires={150}
        >
       <h1> No Show policy:</h1>

       Je ontvangt 24u vooraf de afspraak een sms.
        Kan je niet naar jouw geboekte afspraak komen, gelieve dan 24u vooraf online te verplaatsen. 
       Als je 24u voorhand je afspraak niet hebt kunnen annuleren dan zijn wij genoodzaakt om 50% van de geboekte afspraak in rekening te brengen.
        Kom je niet opdagen zonder te verwittegen, dan wordt het automatisch een No Show en wordt er 100% in rekening gebracht. <br/>
Alvast bedankt voor je begrip.
Tot binnenkort bij Coiffeur Soirée!{" "}
            </CookieConsent>
        <Footer />
      </div>
    </>
  );
}

export default App;
