import React from "react";
import Container from "react-bootstrap/esm/Container";
import logo from './images/logo_black.png';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Col } from "react-bootstrap";


function NavbarNew() {

  return (
    <>
    {/*  <Col className="mt-3 btn-camel text-white" bg="dark">
        <p>Gesloten wegens vakantie van 1 februari tot en met 14 februari.</p>

  </Col> */}


      <Navbar.Brand className="mt-5 nav_logo">
        <a href="/"> <img className="mt-3" src={logo} alt="logo" width={100} />
        </a>
      </Navbar.Brand>
      <Navbar className="mx-auto" bg="white" expand="lg" sticky='top'>
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto ">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="#overmij">Over Mij</Nav.Link>
              <Nav.Link href="#aanpak">Onze Aanpak</Nav.Link>
             {/* <Nav.Link href="#prijslijst">Prijslijst</Nav.Link> */}
              <Nav.Link href="#contact">Contact</Nav.Link>
              <Nav.Link href="https://salonkee.be/salon/coiffeur-soiree?lang=nl&modal=true" target="_blank" variant="light">Book here</Nav.Link>
              <Nav.Link href="https://www.curl-specialist.com/" target="_blank" variant="light">Webshop</Nav.Link>


            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
export default NavbarNew;