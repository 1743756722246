import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { Parallax } from 'react-parallax';
import queen from './images/queen.png';


function Aanpak() {

    return (
        <>
            <Container fluid className=" bg-light">
                <Parallax
                    bgImage={require('./images/onze-aanpak.webp')}
                    bgImageAlt="krullen"
                    strength={200}
                >
                    <div className="aanpak text-center" style={{ height: '200' }} >

                        <img src={queen} className="png-size" height="450" alt="logo" /></div>

                </Parallax>
                <Container id='aanpak'>
                    <Row lg="1" className="text-center" >

                        <Col className="">
                            <h1 className="p-5">Onze aanpak</h1>
                            <p className="text-center">Omdat krullen zeer uniek zijn per individu, bieden wij bij Coiffeur Soirée elke klant een behandeling op maat aan, 
                            aangepast aan jouw noden en wensen. Wij zijn gespecialiseerd in het knippen en verzorgen van Europese krullen en werken uitsluitend met dit haartype.
                             Voor afro/kroes haar verwijzen wij je graag door naar onze collega Shanice Hairdresser (www.shanicehairdresser.com),
                              die gespecialiseerd is in afro/kroes/mixed haar voor zowel kinderen als volwassenen en gebruik maakt van de juiste producten en technieken. 
                              <br />
                              Haar salon is gevestigd aan de Dampoortstraat 91, 9000 Gent.</p>

                            <p>Voor je eerste bezoek aan Coiffeur Soirée raden wij je aan om op het online boekingsplatform Salonkee te kiezen voor de optie 
                                #CURLIES-SNIT+WASSEN-STYLING/DIFFUSE (krullenpakket)#. Dit omvat een volledig krullenpakket met persoonlijk advies en een workshop, 
                                waarbij je leert hoe je je krullen thuis het beste kunt verzorgen en stylen met de juiste producten en technieken.
                                 Tijdens deze sessie beginnen we met een persoonlijk advies over hoe je jouw krullen het beste kunt aanpakken en bespreken we de gewenste snit. 
                                 Vervolgens knippen we je krullen op droog haar en wassen we je haar met de nodige verzorgingsproducten. Hierna volgt onze persoonlijke workshop, waarin onze
                                 Curl specialist je uitleg geeft over hoe je op de juiste manier je producten aanbrengt met de juiste krultechnieken,
                                  zodat je thuis zelf je krullen kunt verzorgen en je er altijd fabulous uitziet.</p>
                         </Col>
                    </Row >
                </Container>
            </Container>

        </>
    );
}

export default Aanpak;
