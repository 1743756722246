import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { Parallax } from 'react-parallax';
import logo1 from './images/logo/bellisima.webp';
import logo6 from './images/logo/cgmethod.webp';
import logo3 from './images/logo/denman.webp';
import logo4 from './images/logo/holycurls.webp';
import logo5 from './images/logo/insight.webp';
import logo2 from './images/logo/LOGO5.webp';
import logo7 from './images/logo/olaplex.webp';
import logo9 from './images/logo/tangleteezer.webp';
import Button from 'react-bootstrap/esm/Button';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'; 
import world from './images/world.png';

function Prijslijst() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_u1gr3oo', 'template_5r34xr6', form.current, 'vWRk0SRsnjgIaqdpY')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };
    return (
        <>
            <Container  fluid className="mt-5 bg-light text-start">
                <Parallax
                    bgImage={require('./images/adviesgesprek.webp')}
                    bgImageAlt="krullen"
                    strength={300}
                >
                    <div className="overmij text-center" style={{ height: '400' }} >
                         
                        <img className="png-size" src={world} height="450" alt="logo" /></div>

                </Parallax>
                <Container id='prijslijst' >

                    <h1  className="p-5 text-center">Prijslijst</h1>
                    <Row>
                        <Col >
                            <Card className="p-3" style={{minHeight:300}} >
                                <Card.Body >
                                    <Card.Title className='text-center'>
                                        <h2>Termen & prijsindicatie <h5>(voor je lengte te bepalen, vergeet niet om het naar beneden te trekken voor je booking)</h5></h2></Card.Title>
                                    <Card.Text>
                                        <br/>
                                        Kort/halflang/lang/xtra lang: <br />
                                        - Boven schouder<br />
                                        - Tussen schouder en bh bandje<br />
                                        - Onder bh bandje<br />

                            <br />
                                        <p className="">Densiteit: hoeveelheid haar <br/>
                                Styling/diffuse: gebruik van producten in combinatie met de afwerking van een diffuser (haardroger met pinnen) <br/>
                                *Veel knopen in de haren? Kom dan met uitgekamd haar om tijd en kosten te besparen. u kan ook de  optie EXTRA – DETANGLEN/UITBORSTELEN kiezen. Geen tijd? Wij doen het voor jou, tegen een extra kost verrekend aan de kassa. Het is mogelijk dat uw afspraak niet kan doorgaan als u daar geen rekening mee houd, dit omdat er geen extra tijd voor voorzien is.</p>
                             
                            
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                       
                    </Row>
                    <Row lg="1" className="  mt-5" >

                        <Col className="">
                           
                            
                        </Col>
                    </Row >
                    <Row className="pb-5" lg={2} md={1} xs={1}>
                        <Col className="mt-5">
                            <h3 className="pb-3 ">ADVIESGESPREK</h3>

                            <Accordion>
                             
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Curl Consult: 30min.  </Accordion.Header>
                                    <Accordion.Body>
                                        <p>We analyseren samen je krullen en je routine, en wordt er een gepersonaliseerde advies gegeven over de juiste producten en het gebruik ervan .Als u een volledige krullenpakket wilt dan is advies en workshop automatisch inbegrepen. Kies dan volgende optie: Curlies - snit + wassen + styling diffuse. Zo start je met een goed verzorgde krullen en zie je ook hoe je thuis je krullen kan verzorgen en stylen .
                                            Onze expertise ligt bij golvend en krullend dames haar.<br />
                                            Voor afro/kroes/mixed haar kindjes en dames verwijs ik jullie graag door naar mijn collega Shanice (www.shanicehairdresser.com), zij is gespecialiseerd in afro/kroes/mixed haar met de nodige advies en de juiste producten gebruik. locatie: Dampoortstraat 91, 9000 Gent.</p>
                                        <h4 className="text-end prijs">  <b>  38€</b></h4>
                                        <p><small><small>Let op: Alle prijzen moeten worden beschouwd als indicatief en kunnen worden gewijzigd op basis; van het type, de duur en de complexiteit van de dienst die u ter plaatse wordt aangeboden.</small></small></p>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col className="mt-5">
                            <h3 className="pb-3 ">COLOR MENU </h3>

                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Kleuringen uitgroei: 30min.</Accordion.Header>
                                    <Accordion.Body>
                                        <p>U kiest deze optie als u enkel uitgroei kleuring wilt doen. Uitgroei is grijsdekking of zelfde toonhoogte natuurlijk haar. Uitgroei balayage kiest u optie balayage. Naar gelang de densiteit van het haar kan er extra product nodig zijn waarvoor een supplement wordt aangerekend.<br />
                                            Extra bescherming met Olaplex: Kort haar: 25 euro  -  Halflang haar: 30 euro  -  Lang haar: 35 euro - Xtra lang haar 40 euro<br />
                                            Dit wordt in het salon besproken en verrekend aan de kassa.
                                            Onze expertise ligt bij golvend en krullend dames haar.<br />
                                            Voor afro/kroes/mixed haar kindjes en dames verwijs ik jullie graag door naar mijn collega Shanice hairdresser (www.shanicehairdresser.com), zij is gespecialiseerd in afro/kroes/mixed haar met de nodige advies en de juiste producten gebruik. locatie: Dampoortstraat 91, 9000 Gent.</p>
                                        <h4 className="text-end prijs">  <b>55€-60€-65€</b><br></br></h4>
                                        <p><small><small>Let op: Alle prijzen moeten worden beschouwd als indicatief en kunnen worden gewijzigd op basis; van het type, de duur en de complexiteit van de dienst die u ter plaatse wordt aangeboden.</small></small></p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Kleuringen volledig: 30min.</Accordion.Header>
                                    <Accordion.Body>
                                        <p>OPGELET!!! Een kleuring is een tintje lichter, natuur kleur of donkerder kleuren of grijsdekking, als je blonder wilt gaan dan kies je voor optie balayage. Naargelang de densiteit van het haar kan er extra product nodig zijn waarvoor een supplement wordt aangerekend.<br />
                                            Extra bescherming met Olaplex: Kort haar: 25  -  Halflang haar: 30  -  Lang haar: 35 / Xtra Lang haar: 40<br />
                                            Dit wordt in het salon besproken en verrekend aan de kassa.
                                            Onze expertise ligt bij golvend en krullend dames haar.<br />
                                            Voor afro/kroes/mixed haar kindjes en dames verwijs ik jullie graag door naar mijn collega Shanice hairdresser (www.shanicehairdresser.com), zij is gespecialiseerd in afro/kroes/mixed haar met de nodige advies en de juiste producten gebruik. locatie: Dampoortstraat 91, 9000 Gent.</p>
                                        <h4 className="text-end prijs">  <b>65€-70€-75€</b></h4>
                                        <p><small><small>Let op: Alle prijzen moeten worden beschouwd als indicatief en kunnen worden gewijzigd op basis; van het type, de duur en de complexiteit van de dienst die u ter plaatse wordt aangeboden.</small></small></p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                
                            
                            </Accordion>
                        </Col>
                        <Col className="mt-5">
                            <h3 className="pb-3 ">CURLIES ENKEL DAMES</h3>

                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Frou/Bangs: 15min.</Accordion.Header>
                                    <Accordion.Body>
                                        <p>U kiest deze optie als u alleen maar je Frou/bangs wilt laten bijknippen . Als u een volledige snit zou willen dan is de Frou/bangs al inbegrepen en kiest u optie enkel snit.</p>
                                        <h4 className="text-end prijs">  <b>  10€</b></h4>
                                        <p><small><small>Let op: Alle prijzen moeten worden beschouwd als indicatief en kunnen worden gewijzigd op basis; van het type, de duur en de complexiteit van de dienst die u ter plaatse wordt aangeboden.</small></small></p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Enkel snit: 30min. </Accordion.Header>
                                    <Accordion.Body>
                                        <p>Hier wordt enkel krullen geknipt op droog haar. Als u ook een advies gesprek wenst  hoe je je krullen best kunt verzorgen, dan kiest u beter de optie Curlies - snit + wassen + styling diffuse.
                                            Onze expertise ligt bij golvend en krullend dames haar.<br />
                                            Voor afro/kroes/mixed haar kindjes en dames verwijs ik jullie graag door naar mijn collega Shanice hairdresser (www.shanicehairdresser.com), zij is gespecialiseerd in afro/kroes/mixed haar met de nodige advies en de juiste producten gebruik. locatie: Dampoortstraat 91, 9000 Gent.</p>

                                        <h4 className="text-end prijs">  <b> 39€-44€-49€</b></h4>
                                        <p><small><small>Let op: Alle prijzen moeten worden beschouwd als indicatief en kunnen worden gewijzigd op basis; van het type, de duur en de complexiteit van de dienst die u ter plaatse wordt aangeboden.</small></small></p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Snit + wassen + styling diffuse (krullenpakket):60min.</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Dit is een krullenpakket incl. persoonlijk advies en workshop zodat je weet hoe je thuis je krullen best kunt verzorgen en stylen.<br />
                                            Naargelang de hoeveelheid haar (densiteit) zoals, lengte, volume of de dikte van het haar,
                                            kan er een extra techniek of product nodig zijn waarvoor een supplement wordt aangerekend.<br />
                                            Vraag in het salon tijdens uw afspraak duidelijk onder welke categorie 'densiteit' je valt om misverstanden achteraf te vermijden.
                                            Zo kan je kort maar heel veel haar hebben (= meer densiteit)) of je kan heel lang maar fijn haar hebben (minder densiteit )
                                            Onze expertise ligt bij golvend en krullend dames haar.<br />
                                            Voor afro/kroes/mixed haar kindjes en dames verwijs ik jullie graag door naar mijn collega Shanice hairdresser (www.shanicehairdresser.com), zij is gespecialiseerd in afro/kroes/mixed haar met de nodige advies en de juiste producten gebruik. locatie: Dampoortstraat 91, 9000 Gent.</p>
                                        <h4 className="text-end prijs">  <b>78€-90€-102€</b></h4>
                                        <p><small><small>Let op: Alle prijzen moeten worden beschouwd als indicatief en kunnen worden gewijzigd op basis; van het type, de duur en de complexiteit van de dienst die u ter plaatse wordt aangeboden.</small></small></p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Wassen + Styling/Diffuse: 45min.</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Naargelang de hoeveelheid haar (densiteit) zoals, lengte, volume of de dikte van het haar,
                                            kan er een extra techniek of product nodig zijn waarvoor een supplement wordt aangerekend.<br />
                                            Vraag in het salon tijdens uw afspraak duidelijk onder welke categorie 'densiteit' je valt om misverstanden achteraf te vermijden.
                                            Zo kan je kort maar heel veel haar hebben (= meer densiteit) of je kan heel lang maar fijn haar hebben (minder densiteit).
                                            Onze expertise ligt bij golvend en krullend dames haar.<br />
                                            Voor afro/kroes/mixed haar kindjes en dames verwijs ik jullie graag door naar mijn collega Shanice hairdresser (www.shanicehairdresser.com), zij is gespecialiseerd in afro/kroes/mixed haar met de nodige advies en de juiste producten gebruik. locatie: Dampoortstraat 91, 9000 Gent.</p>
                                        <h4 className="text-end prijs ">  <b>52€-64€-76€</b></h4>
                                        <p><small><small>Let op: Alle prijzen moeten worden beschouwd als indicatief en kunnen worden gewijzigd op basis; van het type, de duur en de complexiteit van de dienst die u ter plaatse wordt aangeboden.</small></small></p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>Snit + wassen + handdoek droog: 30min.</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Hier wordt geknipt op droog haar, gewassen en handdoek droog gedept incl leave-in excl. adviesgesprek.
                                            Onze expertise ligt bij golvend en krullend dames haar.<br />
                                            Voor afro/kroes/mixed haar kindjes en dames verwijs ik jullie graag door naar mijn collega Shanice hairdresser (www.shanicehairdresser.com), zij is gespecialiseerd in afro/kroes/mixed haar met de nodige advies en de juiste producten gebruik. locatie: Dampoortstraat 91, 9000 Gent.</p>
                                        <h4 className="text-end prijs" >  <b>52€-61€-68€</b></h4>
                                        <p><small><small>Let op: Alle prijzen moeten worden beschouwd als indicatief en kunnen worden gewijzigd op basis; van het type, de duur en de complexiteit van de dienst die u ter plaatse wordt aangeboden.</small></small></p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        
                       
                        <Col className="mt-5">
                            <h3 className="pb-3 ">BEHANDELINGEN</h3>

                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Proteïne/moisture/hydratatie treatment incl. styling/diffuse: 75min.</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Wat is een proteïne haar behandeling? <br />
                                            <br />
                                            Een proteïne haar behandeling zit vol herstellende producten boordevol proteïnen die ons haar gemakkelijk kan absorberen. Haarproducten met proteïne helpen om schade aan de haarlokken te herstellen, waardoor het haar sterker en gezonder wordt.<br />
                                            <br />
                                            Moisture behandeling geeft je droge krullen een boost van hydratatie.<br/>
                                            Hoe gaan we te werk:<br />
                                            Je haar wordt grondig gewassen met de clarifying shampoo om product resten weg te halen en je schubben op te zetten. Hierdoor zal de proteïn diep in de haren nestelen. Na het aanbrengen van de proteïne product word je haar ingepakt in plastic folie en word je 30 min onder de climazon (warmtebron) gezet.<br />
                                            Je haar wordt vervolgens grondig gespoeld en wordt er gestyled naar keuze.</p>
                                        <h4 className="text-end prijs">  <b>85€-100€-120€</b></h4>
                                        <p><small><small>Let op: Alle prijzen moeten worden beschouwd als indicatief en kunnen worden gewijzigd op basis; van het type, de duur en de complexiteit van de dienst die u ter plaatse wordt aangeboden.</small></small></p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Olaplex treatment incl. styling/diffuse: 75min.</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Wat doet Olaplex met krullen?<br />
                                            OLAPLEX is het enige product dat gepatenteerd is om gebroken zwavelbruggen in krullen te herstellen en om het haar van binnenuit te herstellen. Krullend haar is een van de meest fragiele haartypes en vereist extra liefde om ze precies goed te kunnen verzorgen.<br />
                                            <br />
                                            Hoe gaan we tewerk:<br />
                                            Nr1 bond multiplier wordt aangebracht op droog/handdoekdroog haar en wordt 5 min. ingetrokken. Vervolgens wordt Nr2 bond perfector aangebracht en word je haar ingepakt in plastic folie en 20 min onder de climazon (warmtebron) gezet.
                                            <br />
                                            Je haar wordt vervolgens gewassen met shampoo en conditioner en gestyled naar keuze.Naargelang de hoeveelheid haar (densiteit) zoals, lengte, volume of de dikte van het haar,
                                            kan er een extra techniek of product nodig zijn waarvoor een supplement wordt aangerekend.</p>
                                        <h4 className="text-end prijs">  <b>85€-100€-120€</b>µ</h4>
                                        <p><small><small>Let op: Alle prijzen moeten worden beschouwd als indicatief en kunnen worden gewijzigd op basis; van het type, de duur en de complexiteit van de dienst die u ter plaatse wordt aangeboden.</small></small></p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Scalp treatment: 15min.</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Heb je last van talgafscheiding, schilferige huid, jeuk, droge/vette hoofdhuid of andere problemen/klachten? Dan is een scalp treatment geschikt voor jou.<br />
                                            <br />
                                            Hoe gaan we tewerk:<br />
                                            Je haar wordt gewassen met de clarifying shampoo en vervolgens word je hoofdhuid grondig gescrubd met de exfoliating crème met behulp van een scalp massager. Na het uitspoelen brengen we een hydraterende masker aan en laten het paar min intrekken en vervolgens uitspoelen.</p>
                                        <h4 className="text-end prijs">  <b> 25€</b></h4>
                                        <p><small><small>Let op: Alle prijzen moeten worden beschouwd als indicatief en kunnen worden gewijzigd op basis; van het type, de duur en de complexiteit van de dienst die u ter plaatse wordt aangeboden.</small></small></p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>

                        {/* <Col className="mt-5">
                            <h3 className="pb-3 ">FOLLOW UP AFTER COLOR / BALAYAGE</h3>

                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Handdoekdroog incl wassen *: 15min.</Accordion.Header>
                                    <Accordion.Body> 
                                        <h4 className="text-end prijs">  <b>10€-12€-14€-16€</b></h4>
                                        <p><small><small>Let op: Alle prijzen moeten worden beschouwd als indicatief en kunnen worden gewijzigd op basis; van het type, de duur en de complexiteit van de dienst die u ter plaatse wordt aangeboden.</small></small></p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>* Handdoekdroog incl wassen + snit *: 30min.</Accordion.Header>
                                    <Accordion.Body>
                                        <h4 className="text-end prijs">  <b>40€-47€-54€-66€</b></h4>
                                        <p><small><small>Let op: Alle prijzen moeten worden beschouwd als indicatief en kunnen worden gewijzigd op basis; van het type, de duur en de complexiteit van de dienst die u ter plaatse wordt aangeboden.</small></small></p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>* Styling diffuse incl wassen: 45min.</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Wassen en styling diffuser.<br />
                                            <br />
                                            Naargelang de hoeveelheid haar (densiteit) zoals, lengte, volume of de dikte van het haar,
                                            kan er een extra techniek of product nodig zijn waarvoor een supplement wordt aangerekend.<br />

                                            Vraag in het salon tijdens uw afspraak duidelijk onder welke categorie 'densiteit' je valt om misverstanden achteraf te vermijden.<br />

                                            Zo kan je kort maar heel veel haar hebben (= meer densiteit) of je kan heel lang maar fijn haar hebben (minder densiteit).</p>
                                        <h4 className="text-end prijs">  <b>35€-47€-59€-71€</b></h4>
                                        <p><small><small>Let op: Alle prijzen moeten worden beschouwd als indicatief en kunnen worden gewijzigd op basis; van het type, de duur en de complexiteit van de dienst die u ter plaatse wordt aangeboden.</small></small></p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>* Styling diffuse incl wassen + snit: 60min.</Accordion.Header>
                                    <Accordion.Body>
                                        <p>* Snit, wassen en styling diffuser.<br />
                                            <br />
                                            Naargelang de hoeveelheid haar (densiteit) zoals, lengte, volume of de dikte van het haar,
                                            kan er een extra techniek of product nodig zijn waarvoor een supplement wordt aangerekend.<br />

                                            Vraag in het salon tijdens uw afspraak duidelijk onder welke categorie 'densiteit' je valt om misverstanden achteraf te vermijden.<br />

                                            Zo kan je kort maar heel veel haar hebben (= meer densiteit)) of je kan heel lang maar fijn haar hebben (minder densiteit).</p>
                                        <h4 className="text-end prijs">  <b>59€-71€-83€-95€</b></h4>
                                        <p><small><small>Let op: Alle prijzen moeten worden beschouwd als indicatief en kunnen worden gewijzigd op basis; van het type, de duur en de complexiteit van de dienst die u ter plaatse wordt aangeboden.</small></small></p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>olaplex bij kleuring: 15min.</Accordion.Header>
                                    <Accordion.Body>
                                        <h4 className="text-end prijs">  <b>25€</b></h4>
                                        <p><small><small>Let op: Alle prijzen moeten worden beschouwd als indicatief en kunnen worden gewijzigd op basis; van het type, de duur en de complexiteit van de dienst die u ter plaatse wordt aangeboden.</small></small></p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col> */}
                        <Col className="mt-5">
                            <h3 className="pb-3 ">EXTRA - ENKEL DAMES</h3>

                            <Accordion>
                               
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Detanglen/uitborstelen: 30min.</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Dit wordt bijgerekend als er meer tijd nodig is dan voorzien voor je haren uit te borstelen</p>
                                        <h4 className="text-end prijs">  <b>15€-30€</b></h4>
                                        <p><small><small>Let op: Alle prijzen moeten worden beschouwd als indicatief en kunnen worden gewijzigd op basis; van het type, de duur en de complexiteit van de dienst die u ter plaatse wordt aangeboden.</small></small></p>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="p-5">
                <h1  className="mb-5">Contact</h1>
                <Row id="contact" lg={2} md={1} sm={1} xs={1}>
                    <Col className="mx-auto map container-iframe" >
                        <iframe className="responsive-iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1737.8254266468464!2d3.7268796828986557!3d51.055907375727685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xaeff3bdffb76a12e!2sCoiffeur%20Soir%C3%A9e%20-%20Curl%20Specialist!5e0!3m2!1snl!2sbe!4v1664447181912!5m2!1snl!2sbe" width="600" height="400"  ></iframe>
                    </Col>
                    <Col className="adress " style={{ maxWidth: 500 }}>
                        <Card className="shadow" >
                            <Card.Body>
                                <Card.Title>Contact</Card.Title>
                                <Card.Text>
                                    Bij Sint-Jacobs 19 verdieping 1<br></br>
                                    9000 GENT<br></br>
                                    info@Coiffeur-soiree.com  |  Tel: 0487/26.40.19<br></br>
                                    BTW: BE0795.284.984
                                </Card.Text>
                                <OverlayTrigger trigger="click" placement="bottom"  >
                                    <Button className="mb-5 " href="https://salonkee.be/salon/coiffeur-soiree?lang=nl&modal=true" variant="light" target='_blank'> Klik hier voor een afspraak</Button>
                                </OverlayTrigger>
                                <form ref={form} onSubmit={sendEmail}>
                                    <label>Name</label>
                                    <input type="text"  class="form-control mb-4 mt-4" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Naam" name="user_name" />
                                    <label>Email</label>
                                    <input type="email" class="form-control mt-4 mb-4" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" name="user_email" />
                                    <label>Message</label>
                                    <textarea    class="form-control mt-4 mb-4"  placeholder="Message" name="message" />
                                    <input class="btn btn-camel mt-4" type="submit" value="Send" onClick={()=>{ alert('Your message has been sent'); }} />
 
                                </form>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Container>
            <Container fluid className="bg-white">
                <Row className="pt-5 pb-5 text-center ">
                    <Col className="mx-auto m-2">
                        <img src={logo1} height="130" alt="logo" />
                    </Col>
                    <Col className="mx-auto m-2">
                        <img src={logo2} height="130" alt="logo" />
                    </Col>
                    <Col className="mx-auto m-2">
                        <img src={logo3} height="130" alt="logo" />
                    </Col>
                    <Col className="mx-auto m-2">
                        <img src={logo4} height="130" alt="logo" />
                    </Col>
                    <Col className="mx-auto m-2">
                        <img src={logo5} height="130" alt="logo" />
                    </Col>
                    <Col className="mx-auto m-2">
                        <img src={logo6} height="130" alt="logo" />
                    </Col>
                    <Col className="mx-auto m-2">
                        <img src={logo7} height="130" alt="logo" />
                    </Col>

                    <Col className="mx-auto m-2">
                        <img src={logo9} height="130" alt="logo" />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Prijslijst;
